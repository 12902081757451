import { navigate } from '@reach/router'
import { useEffect } from 'react'

const NotFoundPage = () => {
    // Just redirect to home
    useEffect(() => {
        navigate('/')
    }, [])

    return null
}

export default NotFoundPage
